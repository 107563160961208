<template>
  <b-card title="Warranty Registered">
    <b-col cols="12">
      <b-row>
        <b-col><b-button @click="back" variant="primary"> Back </b-button></b-col>  
      </b-row>
      <br>
      <b-row style="margin-bottom: 15px">
        <b-col cols="3"><strong>Item Model</strong></b-col>
        <b-col>: {{ itemModel }} </b-col>
      </b-row>
      <b-row style="margin-bottom: 15px">
        <b-col cols="3"><strong>Item Name</strong></b-col>
        <b-col>: {{ itemName }}</b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="mb-2">
          <div class="filter-responsive">
            <div class="input-group">
              <div class="input-group-prepend">
                <b-dropdown
                  style="margin: 0 !important;"
                  id="dropdown-1"
                  :text="selectedFilter"
                  class="m-md-2"
                >
                  <b-dropdown-item @click="dropDownMenuClick" field="sapDo"
                    >DO</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="barcode"
                    >Barcode</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="serial"
                    >Serial</b-dropdown-item
                  >
                  <b-dropdown-item @click="dropDownMenuClick" field="certNo"
                    >Certificate No</b-dropdown-item
                  >
                </b-dropdown>
              </div>
              <b-form-input v-model="toFilter" placeholder="Filter.." />
            </div>

            <b-button @click="doFilter" variant="primary">Filter</b-button>
            <b-button @click="clearFilter" variant="primary">Clear</b-button>
          </div>
        </b-col>

        <b-col cols="12">
          <div class="filter-grid my-1">
            <div>
              <label>Year</label>
              <b-form-select
                @change="(value) => fireFiltering('year', value)"
                v-model="year"
                :options="yearOptions"
              ></b-form-select>
            </div>
            <div>
              <label>Month</label>
              <b-form-select
                :disabled="year === null"
                @change="(value) => fireFiltering('month', value)"
                v-model="month"
                :options="months"
              ></b-form-select>
            </div>
          </div>
        </b-col>
        <b-col cols="12">
          <b-table
            responsive
            :items="dealers"
            :fields="fields"
            :filter="search"
            show-empty
          >
            <template #cell(generalWarrantyEndDate)="row">
              {{ row.item.generalWarrantyEndDate | formatDate }}
            </template>
            <template #cell(compressorWarrantyEndDate)="row">
              {{ row.item.compressorWarrantyEndDate | formatDate }}
            </template>
            <template #cell(overdueAmount)="row">
              {{ row.item.overdueAmount.$numberDecimal }}
            </template>
            <template #cell(actions)="row">
              <b-dropdown
                id="dropdown-dropleft"
                variant="primary"
                size="sm"
                text="Menu"
                no-caret
              >
                <template #button-content>
                  <feather-icon size="1x" icon="MenuIcon" />
                </template>
                <b-dropdown-item>
                  <b-button
                    @click="doEditDealer(row)"
                    variant="info"
                    class="mr-1"
                  >
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </b-dropdown-item>
              </b-dropdown>
            </template>

            <template #cell(status)="row">
              {{ row.item.status === "" ? "Active" : "Inactive" }}
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
            first-number
            last-number
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>

<script>
import { userAccess, dateFormat } from "@/utils/utils";
import { mapActions, mapState, mapGetters } from "vuex";
// const queryString = require("query-string");

export default {
  data() {
    return {
      totalRows: 1,
      dealers: [],
      itemModel: "",
      itemName: "",
      fields: [
        { key: "sapDo", label: "DO Number" },
        { key: "barcode", label: "Barcode" },
        { key: "serial", label: "Serial No" },
        { key: "certNo", label: "Certificate Number" },
        { key: "generalWarrantyEndDate", label: "General Warranty End Date" },

        {
          key: "compressorWarrantyEndDate",
          label: "Compressor Warranty End Date",
        },
      ],
      filterStatus: false,
      promptDownload: false,
      warrantyToDownload: [],
      pageOptions: [5, 10, 20],
      search: "",
      warranties: [],
      currentPage: 1,
      perPage: 10,
      totalData: 1,
      deleteModal: false,
      idToDelete: null,
      statistic: {},
      warrantyType: null,
      warrantyOptions: [
        { value: null, text: "All" },
        { value: "Registered", text: "Registered" },
        { value: "Cancelled", text: "Cancelled" },
        // { value: 'Registration Issue', text: 'Registration Issue' },
      ],
      year: null,
      month: null,
      yearOptions: [{ value: null, text: "All" }],
      months: [
        { value: null, text: "All" },
        { value: 1, text: "January" },
        { value: 2, text: "February" },
        { value: 3, text: "March" },
        { value: 4, text: "April" },
        { value: 5, text: "May" },
        { value: 6, text: "June" },
        { value: 7, text: "July" },
        { value: 8, text: "August" },
        { value: 9, text: "September" },
        { value: 10, text: "October" },
        { value: 11, text: "November" },
        { value: 12, text: "December" },
      ],
      selectedFilter: "DO",
      selectedField: "sapDo",
      toFilter: "",
    };
  },
  created() {
    // Setup Store To Save Filtering Information
    this.getFilterPaperless("paperless-list");
    this.currentPage = this.reactiveCurrentPage;
    this.warrantyType = this.reactiveWarrantyType;
    this.year = this.reactiveYear;
    this.month = this.reactiveMonth;

    let now = new Date();
    let year = now.getFullYear();
    this.yearOptions.push({ value: year, text: year });

    for (let i = 1; i < 5; i++) {
      this.yearOptions.push({ value: year - i, text: year - i });
    }
    document.title = "Paperless Warranty | RSP";
  },
  mounted() {
    this.queryDelivery();
    let data = {
      dealerCode: this.$route.params.dealerCode,
      itemModel: this.$route.params.itemModel,
    };
    // this.getWarranty(data).then((el) => {
    //   this.itemName = el[0].description;
    //   this.itemModel = data.itemModel;
    //   this.dealers = el;
    // });

    // this.getWarranty();
    // this.getExternalWarranty({
    //   year: this.reactiveYear,
    //   month: this.reactiveMonth,
    //   status: this.reactiveWarrantyType,
    // }).then((data) => {
    //   this.busyLoadingWarranty = false;
    //   this.processMetadata(data.metadata);
    //   this.warranties = data.data;
    //   // let changes = data.data.map((x)=> {return {...x,fullName:`${x.nameFirst} ${x.nameLast} `}})
    //   // this.warranties = changes
    //   // console.log(this.warranties)
    // });

    // this.updateUrl();

    // this.getPaperlessStatistic().then((data) => {
    //   this.statistic = data.data;
    // }).catch((e) => {
    //   console.log(e);
    // });
  },
  destroyed() {
    let field = this.selectedField;
    let filter = this.filterPaperless;

    delete filter[field];

    this.updateFilter({
      name: "paperless-list",
      data: { ...filter },
    });
  },
  methods: {
    detailWarranty(row) {
      this.updateFilter({
        name: "paperless-list",
        data: {
          currentPage: this.currentPage,
        },
      });
      this.$router.push(`/warranty/edit/${row.item._id}`);
    },
    needDisableDeleteButton(item) {
      if (item.registerStatus === "Registered") {
        return true;
      }

      return false;
    },
    back(){
      this.$router.push({name:`dashboard-dealer`, query:{dealerCode:this.$route.params.dealerCode}})
    },
    confirmToDelete(item) {
      this.idToDelete = item._id;
      this.deleteModal = true;
    },
    doDelete() {
      this.deleteExternalWarranty(this.idToDelete)
        .then(() => {
          this.busyLoadingWarranty = true;
          this.getExternalWarranty().then((data) => {
            this.busyLoadingWarranty = false;
            this.processMetadata(data.metadata);
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    ...mapActions({
      getExternalWarranty: "warranty/getExternalWarranty",
      deleteExternalWarranty: "warranty/deleteExternalWarranty",
      getPaperlessStatistic: "warranty/getPaperlessStatistic",
      getWarranty: "dashboard/getWarranty",
      filterRegisWarranty: "dashboard/filterRegisWarranty",
      getFilterPaperless: "filter/getFilter",
      updateFilter: "filter/updateFilter",
    }),
    openDownloadModal() {
      this.promptDownload = true;
      let filter = this.filterPaperless;
      let dataProcess = [];
      let finalData = [];
      this.getExternalWarranty({
        year: this.year,
        month: this.month,
        status: filter.warrantyType,
        forDownload: true,
      }).then((data) => {
        // this.warrantyToDownload=data.data;
        dataProcess = data.data;

        // split into each item
        for (let i = 0; i < dataProcess.length; i++) {
          const element = dataProcess[i];
          for (let j = 0; j < element.itemsData.length; j++) {
            const el2 = element.itemsData[j];
            let address = element.address ? element.address : "";
            let address2 = element.address2 ? element.address2 : "";
            let address3 = element.address3 ? element.address3 : "";
            let buildingName = element.buildingName ? element.buildingName : "";

            finalData.push({
              cert: element.cert,
              fullName: element.fullName,
              salutation: element.salutation,

              address: `${address} ${address2} ${address3} ${buildingName}`,
              customerType: element.customerType,
              email: element.email,
              phone: element.phone,
              postal: element.postal,
              registerType: element.registerType,
              installerName: element.installerName,
              installationDate: element.installationDate,
              registerStatus: element.registerStatus,
              flagExpiry: el2.flagExpiry,
              purchaseDate: element.purchaseDate,
              companyName: element.companyName,

              sapDo: el2.sapDo,
              dealerCode: el2.dealerCode,
              pCode: el2.pcode,
              generalWarrantyDuration: el2.generalWarrantyDuration,
              compressorWarrantyDuration: el2.compressorWarrantyDuration,
              generalWarrantyEndDate: el2.generalWarrantyEndDate,
              compressorWarrantyEndDate: el2.compressorWarrantyEndDate,
              barcode: el2.barcode,
              projectName: el2.projectName,
              itemModel: el2.itemModel,
              serial: el2.serial,
              remarks: el2.remarks,
              createdAt: element.createdAt,
            });
          }

          this.warrantyToDownload = finalData;
        }
      });
    },

    onSelectWarranty(warrantyType) {
      this.busyLoadingWarranty = true;

      if (warrantyType !== null) {
        this.updateFilter({
          name: "paperless-list",
          data: {
            warrantyType: warrantyType,
          },
        });

        this.getExternalWarranty({
          status: warrantyType,
        }).then((data) => {
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata);
        });

        this.reactiveCurrentPage = 1;
        this.currentPage = 1;
      } else {
        this.updateFilter({
          name: "paperless-list",
          data: {
            warrantyType: undefined,
          },
        });

        this.getExternalWarranty().then((data) => {
          this.busyLoadingWarranty = false;
          this.processMetadata(data.metadata);
        });

        this.reactiveCurrentPage = 1;
        this.currentPage = 1;
      }
    },
    savePaperlessFilter(key, value) {
      let tempFilter = this.filterPaperless;

      if (value === null) {
        tempFilter[key] = undefined;

        if (key === "year") {
          tempFilter["month"] = undefined;
          this.month = null;
        }
      } else {
        tempFilter[key] = value;
      }

      this.updateFilter({
        name: "paperless-list",
        data: { ...tempFilter },
      });
    },
    fireFiltering(key, value) {
      // this.savePaperlessFilter(key, value);
      // this.savePaperlessFilter("page", 1);

      // let filter = this.filterPaperless;
      // this.updateUrl();

      // this.busyLoadingWarranty = true;
      // this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then(
      //   (data) => {
      //     this.busyLoadingWarranty = false;
      //     this.processMetadata(data.metadata);
      //   }
      // );
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;
      let obj = {};
      if (this.filterStatus) {
        obj[this.selectedField] = this.toFilter;
      }
      let payload = {
        ...obj,
        year: this.year,
        month: this.month,
        itemModel: itemModel,
        dealerCode: dealerCode,
        // page: this.currentPage,
        entry: this.perPage,
      };
      // console.log("PAYLOAD", payload);
      this.filterRegisWarranty(payload).then((el) => {
        this.dealers = el.data;
        this.processMetadata(el.metadata);
      });
    },
    // updateUrl() {
    //   let tempData = this.filterPaperless;
    //   let query = queryString.stringify({
    //     ...tempData,
    //     status: tempData.warrantyType,
    //   });
    //   this.downloadUrl = `/api/v1/sales-and-purchase/drcc/download/csv?${query}`;
    // },
    getDealerCode(data) {
      if (data.items.length > 0) {
        return data.items[0].dealerCode;
      }

      return undefined;
    },
    frontendFiltered(data) {
      this.busyLoadingWarranty = false;
      this.filterItemSize = data.length;
    },
    onSearch(data) {
      this.busyLoadingWarranty = true;
    },
    dropDownMenuClick(event) {
      console.log("EVENT", event.target.getAttribute("field"));
      let tempFilter = this.filterPaperless;
      let localField = this.selectedField;

      delete tempFilter[localField];

      this.updateFilter({
        name: "paperless-list",
        data: { ...tempFilter },
      });

      this.selectedFilter = event.target.text;
      this.selectedField = event.target.getAttribute("field");
    },
    doFilter() {
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;
      this.filterStatus = true;
      let obj = {};
      obj[this.selectedField] = this.toFilter;
      let payload = {
        ...obj,
        itemModel: itemModel,
        dealerCode: dealerCode,
        // page: this.currentPage,
        entry: this.perPage,
      };
      // console.log("PAYLOAD", payload);
      this.filterRegisWarranty(payload).then((el) => {
        this.dealers = el.data;
        this.processMetadata(el.metadata);
      });

      // this.savePaperlessFilter(this.selectedField, this.toFilter);
      // this.savePaperlessFilter("page", 1);

      // let filter = this.filterPaperless;
      // this.updateUrl();

      // this.busyLoadingWarranty = true;
      // this.getExternalWarranty({
      //   ...filter,
      //   status: filter.warrantyType,
      //   page: 1,
      // }).then((data) => {
      //   this.busyLoadingWarranty = false;
      //   this.processMetadata(data.metadata);
      // });
    },
    clearFilter() {
      // let tempFilter = this.filterPaperless;
      // let localField = this.selectedField;

      // this.selectedField = "model";
      // this.selectedFilter = "Model";
      // this.toFilter = undefined;

      // // console.log(localField)

      // delete tempFilter[localField];

      // this.updateFilter({
      //   name: "paperless-list",
      //   data: { ...tempFilter },
      // });
      let dealerCode = this.$route.params.dealerCode;
      let itemModel = this.$route.params.itemModel;

      this.selectedField = "sapDo";
      this.selectedFilter = "DO";
      this.toFilter = undefined;
      let payload = {
        itemModel: itemModel,
        dealerCode: dealerCode,
        page: this.currentPage,
        entry: this.perPage,
      };

      // console.log("clear", payload);
      this.filterRegisWarranty(payload).then((data) => {
        // console.log("clear", data);
        this.dealers = data.data;
        this.filterStatus = false;
        this.processMetadata(data.metadata);
      });
    },
    processMetadata(metadata) {
      let { totalData } = metadata;
      this.totalRows = totalData;
      // console.log("this.totalRows", this.totalRows);
    },
    queryDelivery() {
      let obj = {};
      if (this.filterStatus) {
        obj[this.selectedField] = this.toFilter;
      }

      this.filterRegisWarranty({
        ...obj,
        itemModel: this.$route.params.itemModel,
        dealerCode: this.$route.params.dealerCode,
        sapDo: this.$route.params.sapDo,
        page: this.currentPage,
        entry: this.perPage,
      })
        .then((res) => {
          // console.log("THIS>PAGE", res);
          this.itemName = res.data[0].description;
          this.itemModel = res.data[0].itemModel;
          this.dealers = res.data;
          this.processMetadata(res.metadata);
        })
        .catch((err) => {
          // console.log("PAGE_ERROR_WARR");
          console.log({ err });
        });
    },
    onPageChange(page) {
      console.log("REGE YO", page);
      this.currentPage = page;
      this.queryDelivery();
      //   this.savePaperlessFilter("page", page);

      //   let filter = this.filterPaperless;
      //   // console.log({filter})
      //   this.updateUrl();

      //   this.busyLoadingWarranty = true;
      //   this.getExternalWarranty({ ...filter, status: filter.warrantyType }).then(
      //     (data) => {
      //       this.busyLoadingWarranty = false;
      //       this.processMetadata(data.metadata);
      //     }
      //   );
      // },
      // processMetadata(metadata) {
      //   this.totalData = metadata.totalData;
    },
  },
  computed: {
    ...mapGetters({
      warrantyList: "dashboard/getWarranty",
    }),
    permission() {
      return userAccess("Paperless Warranty", "warranty_menu");
    },
    ...mapState({
      externalWarranty: (state) => {
        return state.warranty.externalWarranty;
      },
      filterPaperless: (state) => {
        if (state.filter.filterContainer["paperless-list"] !== undefined) {
          let paperlessFilter = state.filter.filterContainer["paperless-list"];
          return paperlessFilter;
        }

        return {};
      },
    }),
    reactiveCurrentPage: {
      get() {
        if (this.filterPaperless.currentPage === undefined) {
          return 1;
        }

        return this.filterPaperless.currentPage;
      },
      set(newVal) {
        this.updateFilter({
          name: "paperless-list",
          data: {
            currentPage: newVal,
          },
        });

        this.currentPage = newVal;
      },
    },
    reactiveWarrantyType() {
      if (this.filterPaperless.warrantyType !== undefined) {
        return this.filterPaperless.warrantyType;
      }

      return null;
    },
    reactiveYear() {
      if (this.filterPaperless.year !== undefined) {
        return this.filterPaperless.year;
      }

      return null;
    },
    reactiveMonth() {
      if (this.filterPaperless.month !== undefined) {
        return this.filterPaperless.month;
      }

      return null;
    },
  },
};
</script>

<style scoped>
.paperless-drop {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.paperless-drop label {
  font-weight: bold;
  text-align: left;
}

.filter-grid {
  display: grid;
  grid-template-columns: auto auto auto;
  /* grid-template-columns: 20%; */
  column-gap: 10px;
}

@media only screen and (max-width: 767px) {
  .paperless-drop {
    width: 100%;
  }

  .filter-grid {
    display: flex;
    flex-direction: column;
  }
}

.filter-responsive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.filter-responsive button {
  margin: 0 0 0 5px;
}

@media screen and (max-width: 480px) {
  .filter-responsive {
    flex-direction: column;
    justify-content: none;
  }

  .filter-responsive button {
    margin: 5px 0 0 0;
    width: 100px;
  }
}
</style>
